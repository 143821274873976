.projects {
    padding-left: 30px;
}


.projects ul {
    margin-top: 0;
}


.tech-stack {
    font-size: 16px;
    width: 75%;
    clear: both;
}

@media print {
    .tech-stack {
        font-size: 10px;
    }
    .project-name {
        font-size: 14px;
    }
}